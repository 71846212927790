import React from 'react'
import { useState, useEffect, useRef } from 'react'


import Select from 'react-select'
import AdminLayout from './AdminLayout/AdminLayout'
import { IKContext, IKUpload } from 'imagekitio-react'
import $ from 'jquery'
import axios from 'axios'


import '../../Components/TagInput/taginput.scss'


import loadable from '@loadable/component'

const ReactTags = loadable(() => import('react-tag-input').then(curr => {
    return curr.WithContext
}))
const JoditEditor = loadable(() => import('jodit-react'))
// const {ReactTags}  =loadable(()=> import ('react-tag-input'))


// const isSSR = typeof window === 'undefined';
// const JoditEditor = !isSSR ? require('jodit-react') : <div />;

// const CKEditor = !isSSR ? require('@ckeditor/ckeditor5-react') : <div />;
// const ClassicEditor = !isSSR ? require('@ckeditor/ckeditor5-build-classic') : <div />;
const AddPost = () => {
    //jodit requirements
    const editor = useRef(null)

    //jodit config
    const config = {
        readonly: false,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: "insert_clean_html"
    }

    const [thumbnail_url, setThumbnail_url] = useState()
    const [upload_url, setUpload_url] = useState()
    const [file_name, setFile_name] = useState()
    const [file_path, setFile_path] = useState()
    const [fileId, setFileId] = useState()

    const [blog_category, setBlogCategory] = useState()

    const [tags, setTags] = useState([{ id: 'blog', text: 'blog' }])
    const setTagfunc = (qtags) => {
        setTags(qtags)
    }



    const [blog_data, setBlogData] = useState({
        blog_post_title: '',
        blog_post_content: '<p>Sample</p>',
        blog_post_source: '',
        blog_post_source_url: '',
        blog_post_category: '',
        blog_post_tags: [{ id: 'blog', text: 'blog' }],
        blog_post_image_file_name: '',
        blog_post_image_file_path: '',
        blog_post_image_file_url: '',
        blog_post_image_file_id: '',
        blog_post_image_full_url: '',
        blog_post_is_trending: 'false',
        blog_post_is_popular: 'false',
        blog_post_is_featured: 'false',
        blog_post_date: '',
        blog_post_time: '',
        blog_post_status: 'published',
        blog_post_author_username: 'admin',
        blog_post_author_designation: '',
        blog_post_author_image_url: '',
        blog_post_author_name: '',
        blog_post_watch_count: 0,
        blog_post_title_slug: '',
        blog_post_time_stamp: ''

    })


    useEffect(() => {

        setBlogData({ ...blog_data, blog_post_tags: tags })

    }, [tags])

    const onError = (err) => {
        console.log('image Error', err);
    }
    const onSuccess = (url) => {
        setThumbnail_url(url.thumbnailUrl)
        setUpload_url(url.url)
        setFile_name(url.name)
        setFile_path(url.filePath)
        setFileId(url.fileId)
        blog_data.blog_post_image_file_name = url.name
        blog_data.blog_post_image_file_path = url.filePath
        blog_data.blog_post_image_file_url = url.url
        blog_data.blog_post_image_file_id = url.fileId
        blog_data.blog_post_image_full_url = url.thumbnailUrl
        setBlogData({ ...blog_data })

    }

    const deleteImageUpload = async (file_id) => {

        const response = await axios.get(`https://image-api-node.onrender.com/delete/${file_id}`)
        if (response.status == 200) {
            setThumbnail_url('')
            setUpload_url('')
            setFile_name('')
            setFile_path('')
            setFileId('')
        }
    }

    //get category function

    const getCategory = async () => {
        const query = { 'function': 'getCategory' }
        const response = await axios.post(`https://webhooks.mongodb-realm.com/api/client/v2.0/app/diptnc-blog-ngzlx/service/blog_category/incoming_webhook/webhook0`, query)
        setBlogCategory(response.data)

    }



    const addTimeStamp = () => {
        //date in yyyy-mm-dd format
        var today = new Date();
        var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

        //unique time stamp
        var time_stamp = Date.now();


        setBlogData({ ...blog_data, blog_post_date: date, blog_post_time: today.toLocaleTimeString(), blog_post_time_stamp: time_stamp });



    }

    //save blog function

    useEffect(() => {
        addTimeStamp()

    }, [blog_data.blog_post_date]);

    


    const saveBlogFunction = async (e) => {


        e.preventDefault()
        let query = { blog_data, 'function': 'savePost' }
        const response = await axios.post(`https://webhooks.mongodb-realm.com/api/client/v2.0/app/diptnc-blog-ngzlx/service/blog_post/incoming_webhook/webhook0`, query)
        if (response.status == 200) {
            alert('Blog Saved')
            // empty all the fields
            setThumbnail_url('')
            setUpload_url('')
            setFile_name('')
            setFile_path('')
            setFileId('')

            setBlogData({
                blog_post_title: '',
                blog_post_content: '<p>Sample</p>',
                blog_post_source: '',
                blog_post_source_url: '',
                blog_post_category: '',
                blog_post_tags: ["blog"],
                blog_post_image_file_name: '',
                blog_post_image_file_path: '',
                blog_post_image_file_url: '',
                blog_post_image_file_id: '',
                blog_post_image_full_url: '',

                blog_post_is_trending: 'false',
                blog_post_is_popular: 'false',
                blog_post_is_featured: 'false',
                blog_post_date: '',
                blog_post_time: '',
                blog_post_status: 'published',
                blog_post_author_username: '',
                blog_post_author_name: '',
                blog_post_author_designation: '',
                blog_post_author_image_url: '',

            })
        }


    }

    const getAuthor = async () => {

        const query = { 'function': 'getAuthorDetails', 'username': `${blog_data.blog_post_author_username}` }
        const response = await axios.post(`${process.env.GATSBY_API_FETCH_AUTHOR_DETAILS}`, query)

        setBlogData({ ...blog_data, blog_post_author_username: response.data[0].username, blog_post_author_name: response.data[0].name, blog_post_author_designation: response.data[0].designation, blog_post_author_image_url: response.data[0].image_url })
    }
    useEffect(() => {
        //get category
        getCategory()

        //get author
        getAuthor()




        $('#delete_uploded_image').on('click', (event) => {
            //prevent default
            event.preventDefault();
            //get the file id
            const fileId = $('#fileId').val();
            // console.log('deleteImageUpload', fileId)
            deleteImageUpload(fileId);

        })

        //get all the form fields using react hooks


    }, [])
    //slugify function for title
    const slugify = (value) => {

        return value.toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\-\-+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');            // Trim - from end of text
    }

    //handle input function
    const handleInput = (event) => {
        const { name, value } = event.target;
        //change the title slug
        if (name == 'blog_post_title') {

            setBlogData({ ...blog_data, blog_post_title: value, blog_post_title_slug: slugify(value) })
        } else {
            setBlogData({ ...blog_data, [name]: value })
        }


        // console.log('slugify', blog_data)

    }
    const KeyCodes = {
        comma: 188,
        enter: 13
    };

    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        setTags([...tags, tag]);
    };

    return (
        <>
            <AdminLayout>

                <div >
                    <div className="container" style={{ margin: '10px auto', maxWidth: '800px', background: '#D7D7D7' }}>
                        <h1 className="text-center">upload post</h1>
                        <div className="row">
                            <div className="col-md-12">
                                {/* upload post */}
                                <form method="post" onSubmit={(e) => { saveBlogFunction(e) }} encType="multipart/form-data" >
                                    <div className="form-group">
                                        <label >Title</label>
                                        <input type="text" name="blog_post_title" id="blog_post_title" className="form-control" placeholder="Title" value={blog_data.blog_post_title} onChange={handleInput} required />
                                    </div>
                                    <div className="form-group">
                                        <label>Content</label>

                                        <JoditEditor
                                            ref={editor}
                                            value={blog_data.blog_post_content}
                                            config={config}
                                            tabIndex={1} // tabIndex of textarea
                                            onBlur={newContent => setBlogData({ ...blog_data, blog_post_content: newContent })}// preferred to use only this option to update the content for performance reasons
                                            onChange={newContent => { }}

                                        />

                                    </div>
                                    <div className="form-group">
                                        <label>Source</label>
                                        <input type="text" name="blog_post_source" id="blog_post_source" className="form-control" placeholder="Source" value={blog_data.blog_post_source} onChange={handleInput} />
                                    </div>
                                    <div className="form-group">
                                        <label>Source url</label>
                                        <input type="text" name="blog_post_source_url" id="blog_post_source_url" className="form-control" placeholder="Source url" value={blog_data.blog_post_source_url} onChange={handleInput} />
                                    </div>
                                    <div className="form-group">
                                        <label>Category</label>


                                        <Select isMulti
                                            name="blog_post_category" onChange={val => (setBlogData({ ...blog_data, blog_post_category: val }))} options={blog_category && blog_category.map((curr) => ({
                                                value: curr.cat_slug, label: curr.cat_name
                                            })
                                            )} />


                                    </div>
                                    <div className="form-group">
                                        <label>Tags</label>
                                        <ReactTags
                                            delimiters={delimiters}
                                            tags={tags}
                                            editable={true}
                                            onChange={(newTags) => setTagfunc(newTags)}

                                            addKeys={delimiters} //keyboard key code

                                            handleDelete={handleDelete}
                                            handleAddition={handleAddition}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Image</label>

                                        <IKContext publicKey="public_U9fWCsM10YV6ydv/aUaxPg10Gts=" urlEndpoint="https://ik.imagekit.io/diptnc" authenticationEndpoint="https://image-api-node.onrender.com/auth">


                                            <IKUpload
                                                className="form-control"


                                                folder={`/post`}
                                                onError={onError}
                                                onSuccess={onSuccess}
                                            />
                                        </IKContext>
                                        <div className="form-group">
                                            <label>image details</label>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>file name</label>
                                                        <input onChange={handleInput} type="text" name="blog_post_image_file_name" value={file_name} className="form-control" placeholder="file name" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>file path</label>
                                                        <input onChange={handleInput} type="text" name="blog_post_image_file_path" value={file_path} className="form-control" placeholder="file name" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>file url</label>
                                                        <input onChange={handleInput} type="text" name="blog_post_image_file_url" value={upload_url} className="form-control" placeholder="file name" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>file id</label>

                                                        <input onChange={handleInput} id="fileId" name="blog_post_image_file_id" className="form-control" type="text" value={fileId} placeholder="file id" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">

                                                        <img src={thumbnail_url} alt="" srcset="" />
                                                        <button id="delete_uploded_image" className="btn btn-danger">del</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Image(manual)</label>
                                            <input type="text" name="blog_post_image_full_url" className="form-control" placeholder="image full url" />
                                        </div>


                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>Is Trending</label>
                                                    <select name="blog_post_is_trending" onChange={handleInput} className="form-control">
                                                        <option value='false'>false</option>
                                                        <option value="true">true</option>

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>Is Popular</label>
                                                    <select name="blog_post_is_popular" onChange={handleInput} name="blog_post_is_popular" className="form-control">
                                                        <option value='false'>false</option>
                                                        <option value="true">true</option>

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>Is featured</label>
                                                    <select name="blog_post_is_featured" onChange={handleInput} className="form-control">
                                                        <option value='false'>false</option>
                                                        <option value="true">true</option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>Publish Date</label>
                                                    <input type="date" name="blog_post_date" onChange={handleInput} className="form-control" placeholder="Publish Date" />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>Publish Time</label>
                                                    <input type="time" name="blog_post_time" onChange={handleInput} className="form-control" placeholder="Publish Time" />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>Status</label>
                                                    <select name="blog_post_status" onChange={handleInput} className="form-control">
                                                        <option value="published">published</option>
                                                        <option value="draft">draft</option>
                                                        <option value="deleted">deleted</option>

                                                    </select>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="form-group">

                                            <label>Author</label>
                                            <input type="text" id="blog_post_author_username" name="blog_post_author_username" value={blog_data.blog_post_author_username} onChange={handleInput} className="form-control" placeholder="Author username" />
                                        </div>
                                        <div className="form-group text-center p-2">
                                            <input className="btn btn-success text-center justify-content-center justify-self-center" type="submit" value="post" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pb-4"></div>
            </AdminLayout>
        </>
    )
}

export default AddPost
